import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import BannerNepal from "../components/Pages/Region/BannerNepal";
import MRC from "../components/Pages/Region/MRC";
import RegionMap from "../components/Pages/Region/RegionMap";
import Partners from "../components/Pages/Region/Partners";
import CaseStudy from "../components/Pages/Region/CaseStudy";
import AdditonalResources from "../components/Pages/Region/AdditonalResources";
import Insights from "../components/Pages/Region/Insights";
import { AiOutlineSolution } from "@react-icons/all-files/ai/AiOutlineSolution";
import { FaHandshake } from "@react-icons/all-files/fa/FaHandshake";
import { nepalInsights } from "../data/nepal-insights";
const NepalPage = () => {
  const IconClassName =
    "w-12 h-12 object-contain text-darkblue group-hover:text-black transition-all ease duration-300 shrink-0";
  const nepalPartners = [
    {
      link: "https://samariutthan.org.np/",
      title: "Samari Utthan Sewa (SUS)",
      icon: "/images/Samari Utthan Sewa (SUS) logo.png",
    },
    {
      link: "https://needsnepal.org.np/",
      title:
        "National Environment &amp; Equity Development Society (NEEDS) Nepal",
      icon: "/images/NEEDS Nepal logo.jpg",
    },
    {
      link: "http://www.mahilaekata.org/",
      title: "Nepal Mahila Ekata Samaj (NMES)",
      icon: "/images/Nepal Mahila Ekata Samaj (NMES) logo.png",
    },
  ];
  const mrcContent = [
    {
      icon: <AiOutlineSolution className={IconClassName} />,
      description:
        "Developing scalable, context-appropriate models to improve last-mile access to social protection.",
    },
    {
      icon: <FaHandshake className={IconClassName} />,
      description:
        "Collaborating with government and industry to build sustainable, systemic solutions to address challenges.",
    },
  ];
  const learnMore = [
    {
      link: "https://publications.iom.int/books/migration-nepal-country-profile-2019#:~:text=IOM%20Publications%20Platform,-Main%20navigation&text=This%20inaugural%20Migration%20Profile%20for,working%20overseas%20or%20has%20returned",
      text: "Migration in Nepal",
    },
    {
      link: "https://link.springer.com/chapter/10.1007/978-3-030-44010-7_13",
      text: "Internal Migration in Nepal",
    },
  ];
  return (
    <Layout>
      <Seo title="MRC in Nepal" url="https://migrantresilience.org/nepal" />
      <BannerNepal />
      <MRC
        heading={"MRC in Nepal"}
        description="MRC is working with local actors in Nepal to strengthen access to social protection for internal migrants and help build their resilience. MRC will support local actors in -"
        data={mrcContent}
      />
      <Partners
        title="Nepal CBO partners"
        data={nepalPartners}
        containerClass="lg:grid-cols-3 md:grid-cols-3 grid-cols-1"
      />

      <RegionMap
        image="/images/Migration Map_Nepal 2.png"
        title="Provinces MRC is active in"
      />
      <Insights
        sectionHeading={"About internal migration in Nepal"}
        data={nepalInsights}
      />
      <CaseStudy
        title="Raj*- a migrant working as a waiter in Nepal"
        image="/images/nepal/case-story.jpg"
      >
        <p>
          “My family had taken a loan from the cooperative to build a house in
          the village, but we were unable to pay off the loan, we could not save
          from the money we earned through agriculture. I decided to migrate to
          the city to earn a better income. I started working as a waiter at a
          restaurant in Kathmandu, the income that I earn here is helping me pay
          off the debt. Migration has helped me in other ways as well. I support
          my parents financially and pay for my brothers’ education. Personally,
          my health has gotten better, as in the village, we did not have access
          to hospitals and medicine but here I can buy medicines nearby whenever
          I feel unwell. Since coming to the city, I have also learnt how to
          speak to other people and deal with problems and difficulties on my
          own.
        </p>
        <p>
          However, my migration has also made a few things difficult back home.
          As there is less manpower for agricultural work, my younger siblings
          have to take on my tasks. Sometimes my parents take the help of local
          villagers through labour exchange or wages which is an extra cost they
          have to bear. Also, I am the only educated person in the family, so I
          used to take care of all financial matters and now, my family often
          finds it difficult to take care of finances on their own. They try to
          reach out to me sometimes, but I am not always available because of my
          work. If there is an emergency, they have to take the help of
          villagers, but they contact me most of the time as they cannot rely on
          villagers and our relatives also live far away. Sometimes I also feel
          very lonely in the city, especially during festivals but I cannot even
          call them every day because my phone does not always have sufficient
          balance.”
        </p>
        <p className="text-sm">
          *This story is anonymous and Raj is a pseudonym.
        </p>
      </CaseStudy>
      <AdditonalResources learnMore={learnMore} />
    </Layout>
  );
};
export default NepalPage;
