export const nepalInsights = [
  {
    heading:
      "Increase in rural to urban migration in the country has led to a loss of labour in rural areas:",
    text: `The most prominent labour movement in the country has been from the mountains to the Terai region in Nepal. Some districts have experienced an outmigration of up to half of their population.<sup><a href="https://publications.iom.int/system/files/pdf/mp_nepal_2019.pdf" target="_blank" rel="noreferrer">[9]</a></sup> Studies have shown that this has led to a shortage of agricultural labour in rural areas. In some regions of Nepal, rural communities use agricultural traditions like the <i>'parma'</i><sup>[10]</sup>, a reciprocal labour exchange system between households to supplement labour loss.<sup><a href="https://forestecosyst.springeropen.com/articles/10.1186/s40663-018-0155-x" target="_blank" rel="noreferrer">[11]</a></sup>`,
    image: "/images/nepal/insight-1.jpg",
    extra:
      "<span class='text-blue'>[10]</span> The Parma system in Nepal is a reciprocal exchange of labour. Households come together to conduct similar work such as sowing or harvesting on each household farm during agricultural season. There is no payment involved, but every contributing household is intended to receive the same service in return.",
  },
  {
    heading:
      "Earthquakes and natural disasters – aggravated by climate change – have driven internal migration in the country:",
    text: `More than 80 percent of the total population of Nepal is at high risk from natural hazards like floods, landslides, earthquakes and Glacial Lake Outburst Floods (GLOF).<sup><a href="https://www.undp.org/nepal/publications/nepal-disaster-report-2017-road-sendai#:~:text=More%20than%2080%20percent%20of,period%20of%202015%20and%202016." target="_blank" rel="noreferrer">[12]</a></sup> Landslides exacerbate water scarcity and climate events can devastate entire villages, leading to increased internal migration. The Global Report on Internal Displacement (GRID,2023) estimated 93,000 internal displacement disasters in the country which led to 58,000 internally displaced people in 2022. Across South Asia, Nepal has the fifth highest internally displaced people.<sup><a href="https://www.internal-displacement.org/global-report/grid2023/" target="_blank" rel="noreferrer">[13]</a></sup>`,
    image: "/images/nepal/insight-2.jpg",
  },
  {
    heading:
      "Challenges related to the implementation of labour laws increase the vulnerability of migrant workers:",
    text: `In Nepal, amendments to the Labour Act in 2017 and Labour Rules in 2018, extended the applicability of the act to firms of any size. However, cases of collective bargaining, safety and health, or labour relation committees do not fall under the new amendment.<sup><a href="https://vidhilegal.com/general-introduction-to-labor-laws-in-nepal/" target="_blank" rel="noreferrer">[14]</a></sup> This could result in those working in small sized enterprises being susceptible to exploitation. Furthermore, compliance with these laws has implementation challenges. For instance, enforcement of minimum wage laws is limited, resulting in cases of workers being paid below the minimum wage.<sup><a href="https://www.spotlightnepal.com/2019/05/22/application-minimum-wages-nepal-challenges-and-opportunities/" target="_blank" rel="noreferrer">[15]</a></sup>`,
    image: "/images/nepal/insight-3.jpg",
  },
  {
    heading:
      "The Social Security Fund in Nepal was recently expanded to cover informal sector workers and self-employed individuals:",
    text: `There are 87 social security schemes in Nepal.<sup><a href="https://www.internal-displacement.org/global-report/grid2023/" target="_blank" rel="noreferrer">[16]</a></sup> One of the key schemes for workers is the Social Security Fund, launched in 2018, initially covering only formal sector workers. Recently the scheme was expanded to include informal workers and self-employed individuals. The fund includes four schemes: medical and safe maternity protection, accidents and disabilities, dependent family protection scheme, and old age protection scheme.<sup><a href="https://www.ilo.org/wcmsp5/groups/public/---asia/---ro-bangkok/---ilo-kathmandu/documents/publication/wcms_869013.pdf" target="_blank" rel="noreferrer">[17]</a></sup>`,
    image: "/images/nepal/insight-4.jpg",
  },
];

{
  /* <sup><a href="" target="_blank" rel="noreferrer">[2]</a></sup> */
}
